#contact {
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  /* padding-top: calc(6rem + 74px); */
  /* padding-bottom: 6rem; */
}
#contact .btn-social {
  border-radius: 100%;
  display: inline-flex;
  width: 2.25rem;
  height: 2.25rem;
  font-size: 1.25rem;
  justify-content: center;
  align-items: center;
  border: 1.5px solid;
}
#contact .btn-social img{
  width: 20px;
  height: 20px;
}
.contactIcon{
  font-size: 30px;
  margin: auto;
}

.contactDetail  h4 {
  padding: 0 0 0 12px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  /* color: #050d18; */
}
.contactDetail  p {
  padding: 0 0 0 12px;
  font-size: 16px;
  /* font-weight: 600; */
  margin-bottom: 5px;
  color: #050d18;
}
#contact  .rounded-0 {
  height: 100%;
}

/* #contact:before {
  content: "";
  background: #f8f9fabd;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
} */
