#mainNav {
  /* padding-top: 1rem;
  padding-bottom: 1rem; */
  /* font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
  font-weight: 700;
}
/* #mainNav .navbar-brand {
  color: #fff;
} */
#mainNav .navbar-nav {
  margin-top: 1rem;
}
/* #mainNav .navbar-nav li.nav-item a.nav-link {
  color: #fff;
} */
#mainNav .navbar-nav li.nav-item a.nav-link:hover {
  color: #185e9e;
}
/* #mainNav .navbar-nav li.nav-item a.nav-link:active, #mainNav .navbar-nav li.nav-item a.nav-link:focus {
  color: #fff;
} */
#mainNav .navbar-nav li.nav-item a.nav-link.active {
  color: #185e9e;
}
#mainNav .navbar-toggler {
  font-size: 80%;
  padding: 0.8rem;
}

@media (min-width: 992px) {
  #mainNav {
    padding: 0;
    /* padding-top: 1.5rem;
    padding-bottom: 1.5rem; */
    transition: padding-top 0.3s, padding-bottom 0.3s;
  }
  #mainNav .navbar-brand {
    font-size: 1.25em;
    transition: font-size 0.3s;
  }
  #mainNav .navbar-nav {
    margin-top: 0;
    font-size: 12px;
  }
  #mainNav .navbar-nav > li.nav-item > a.nav-link.active {
    color: #fff;
    background: #185e9e;
  }
  #mainNav .navbar-nav > li.nav-item > a.nav-link.active:active, #mainNav .navbar-nav > li.nav-item > a.nav-link.active:focus, #mainNav .navbar-nav > li.nav-item > a.nav-link.active:hover {
    color: #fff;
    background: #185e9e;
  }

  #mainNav.navbar-shrink {
    /* padding-top: 0.5rem;
    padding-bottom: 0.5rem; */
  }
  #mainNav.navbar-shrink .navbar-brand {
    font-size: 1.5em;
  }
}