.errorPage h1 {
  font-size: 3rem;
}
@media (min-width: 576px) {
  .errorPage h1 {
    font-size: 5rem;
  }
}
@media (min-width: 768px) {
  .errorPage h1 {
    font-size: 6rem;
  }
}
@media (min-width: 992px) {
  .errorPage h1 {
    font-size: 7rem;
  }
}
/* .errorPage h1{
  font-size: 6rem;
} */
