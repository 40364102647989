.about-photo{
  width: 80%;
  max-width: 250px;
}
/* \002B9E */
#about .btn:hover{
  background-color: #5499C7 !important;
  border-color: #5499C7 !important;
  color: white !important;
  transition: 0.5;
}

#about .rounded-circle{
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

@media (min-width: 768px) {
  .about-photo{
    width: 100%;
  }
}
