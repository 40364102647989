/* #skill{
  background-color: whitesmoke;
} */

#skill li{
  list-style: none;
}
#skill .badge, #skill .action-text{
  cursor: pointer;
  transition: 0.5s;
}
#skill .badge:hover{
  background-color: #185e9e !important;
  color: white !important;
}
#skill .badge:active{
  background-color: #185e9e !important;
  color: white !important;
}
#skill .action-text:hover{
  color: #185e9e !important;
}
#skill .active-100{
  background-color: #185e9e !important;
  color: white !important;
}
#skill .active-75{
  background-color: rgba(24,94,158,0.75) !important;
  color: white !important;
}
.tech-card{
  width: 150px;
  cursor: pointer;
}
.perso-card{
  width: 180px;
  cursor: pointer;
}
.lang-card{
  width: 180px;
  cursor: pointer;
}
.img-personnality{
  width: 70px;
  height: 70px;
}