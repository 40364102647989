.chooseTech {
  max-width: 500px;  
}

.chooseTech label{
  background-color: #e5ebf2;
  color: #185e9e;
}
.chooseTech label:hover{
  background-color: #c8dbec !important;
  border-color: #c8dbec !important;
}
.chooseTech label:active{
  background-color: #c8dbec !important;
  border-color: #c8dbec !important;
}
.chooseTech .btn-check:checked + .btn{
  background-color: #104F8A !important;
  border-color: #104F8A !important;
  color: white !important;
}
#option-php{
  border-bottom-left-radius: 24px;
  border-top-left-radius: 24px;
}
#option-dart{
  border-bottom-right-radius: 24px;
    border-top-right-radius: 24px;
}
