.portfolio-item {
  aspect-ratio: 11/8;
  background-size: cover;
  background-position: center;
}
.portfolio .portfolio-item {
  cursor: pointer;
  position: relative;
  display: block;
  max-width: 25rem;
  /* border-radius: 0.5rem; */
  overflow: hidden;
}
.portfolio .portfolio-item .portfolio-item-caption {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  background-color: #1f547adb;
}
.portfolio .portfolio-item .portfolio-item-caption:hover {
  opacity: 1;
}
.portfolio
  .portfolio-item
  .portfolio-item-caption
  .portfolio-item-caption-content {
  font-size: 1.5rem;
}

.portfolio-modal .btn-close {
  color: #1f547adb;
  font-size: 2rem;
  padding: 1rem;
}
.portfolio-modal .portfolio-modal-title {
  font-size: 2.25rem;
  line-height: 2rem;
}
.portfolio-item img {
  width: 400px;
  height: 250px;
}
.portfolio-modal img {
  width: 100%;
  aspect-ratio: 16 / 9;
}
.portfolio .fa-plus {
  height: 80px;
}
@media (min-width: 992px) {
  .portfolio-modal .portfolio-modal-title {
    font-size: 3rem;
    line-height: 2.5rem;
  }
}
