
#about,#portfolio,#contact, #skill,#career, #service{
    min-height: 100vh;
}
.page-section {
    padding: 5.5rem 0;
  }
.page-section .page-section-heading {
    font-size: 2.25rem;
    line-height: 2rem;
  }
  .page-transition{
    display: none;
  }
  @media (min-width: 992px) {
    .page-transition{
      display: block;
      padding: 2rem 0;
    }
    .page-section .page-section-heading {
      font-size: 3rem;
      line-height: 2.5rem;
    }
    .page-transition .page-transition-heading {
      font-size: 3rem;
      line-height: 2.5rem;
    }
  }