.masthead {
  height: 100vh;
  background-size: cover;
  background-position: center;
  /* padding-top: calc(6rem + 74px); */
  /* padding-bottom: 6rem; */
}
/* .masthead:before {
  content: "";
  background: #f8f9fabd;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
} */
.masthead nav{
  /* z-index: 2; */
  transition: 0.5s;
}
.masthead nav:hover{
  background-color: white !important;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
}
.masthead>div, .masthead>ul{
    z-index: 2;
}
.masthead .masthead-heading {
  font-size: 2.75rem;
  line-height: 2.75rem;
}
.masthead .masthead-subheading {
  font-size: 1.25rem;
}
.masthead .masthead-avatar {
  width: 15rem;
}
.masthead .svg-inline--fa{
    font-size: 55px;
    cursor: pointer;
    transition: 0.5s;
  }
  .masthead .svg-inline--fa:hover{
    color: black;
  }
@media (min-width: 992px) {
  .masthead .masthead-heading {
    font-size: 6rem;
    line-height: 3.5rem;
  }
  .masthead .masthead-subheading {
    font-size: 1.5rem;
  }
}
