.service-card{
  cursor: pointer;
  transition: 0.75s;
}
.service-card p{
  transition: 0.75s;
}
.service-card img{
  width: 70px;
  /* height: 150px; */
}
.number .description{
  border: 2px solid transparent;
  transition: 0.5s;
  cursor: pointer;
}

.number:hover .description{
  border-bottom: 2px solid #185e9e;
}
.number:active .description{
  border-bottom: 2px solid #185e9e;
}